<template>
  <!--渲染表单-->
  <div>
    <el-form v-if="mode === 'PC'" ref="form" class="process-form" label-position="top" :rules="rules" :model="_value">
      <div :class="{ readonly: isReadonly(item) }" v-if="showItem(item)" v-for="(item, index) in forms"
        :key="item.name + index" 
        :style="{ display: item.name !== 'SpanLayout' && item.name !== 'Description' && item.name !== 'TableList' && item.name !== 'fieldlist' && item.name !== 'TextareaInput' ? 'inline-flex' : 'block', width: item.name !== 'SpanLayout' && item.name !== 'Description' && item.name !== 'TableList' && item.name !== 'fieldlist' && item.name !== 'TextareaInput' ? '50%' : '95%' }">
        <el-form-item v-if="item.name !== 'SpanLayout' && item.name !== 'Description' && item.name !== 'TableList'"
          :prop="item.id" :label="item.title">
          <form-design-render :ref="item.id" v-model="_value[item.id]" :readonly="isReadonly(item)" :form-data="_value"
            :mode="mode" :config="item" />
        </el-form-item>
        <el-form-item v-else-if="item.name == 'TableList'" :prop="item.id" :label="item.title">
          <!-- {{_value[item.id]}} -->
          <form-design-render :ref="item.id" v-model="_value[item.id]" :readonly="isReadonly1(item, _value[item.id])"
            :form-data="_value" :mode="mode" :config="item" :resultTableList="resultTableList" />
        </el-form-item>
        <form-design-render v-else :ref="item.id" v-model="_value" :readonly="isReadonly(item)" :form-data="_value"
          :mode="mode" :config="item" />
      </div>
    </el-form>
    <div v-else class="process-form">
      <div :class="{ readonly: isReadonly(item) }" v-if="showItem(item)" v-for="(item, index) in forms"
        :key="item.name + index">
        <form-item v-if="item.name !== 'SpanLayout' && item.name !== 'Description'" :model="_value"
          :rule="rules[item.id]" :ref="item.id" :prop="item.id" :label="item.title">
          <form-design-render :readonly="isReadonly(item)" :formData="_value" :ref="item.id + '_item'"
            v-model="_value[item.id]" :mode="mode" :config="item" />
        </form-item>
        <form-design-render :ref="item.id" :readonly="isReadonly(item)" :formData="_value" v-else v-model="_value"
          :mode="mode" :config="item" />
      </div>
    </div>
  </div>
</template>

<script>
import { Field, Form } from "vant";
import { CompareFuncs } from "./components/compare/CompareOptions";
import FormItem from "@/components/common/FormItem";
import FormDesignRender from "@/views/admin/layout/form/FormDesignRender";
import { getPayWay, getPart, getArea, getFault } from "@/api/design";

const VForm = Form;
export default {
  name: "FormRender",
  components: { FormItem, FormDesignRender, VForm, Field },
  props: {
    resultTableList: {
      type: String,
      default: null
    },
    forms: {
      type: Array,
      default: () => {
        return [];
      },
    },
    config: {
      type: Object,
      default: () => {
        return {
          ruleType: "SIMPLE",
          rules: [],
        };
      },
    },
    process: {
      type: Object,
      default: () => {
        return {};
      },
    },
    //当前节点，用来联动表单权限
    currentNode: {
      type: String,
      default: null,
    },
    value: {
      type: Object,
      default: () => {
        return {};
      },
    },
    mode: {
      type: String,
      default: "PC",
    },
  },
  data() {
    return {
      perm: 'R',
      formRules: {},
      //缓存下原始权限设置
      formPermHis: {},
      compareFunc: {},
      //缓存旧值
      oldFormData: {},
      //缓存所有用到的条件字段
      conditionFields: new Set(),
      execute: null,
    };
  },
  computed: {
    _value: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
    rules() {
      let rules = {};
      this.loadFormConfig(this.forms, rules);
      return rules;
    },
    formItemMap() {
      const map = new Map();
      this.loadFormItemMap(this.forms, map);
      return map;
    },
  },
  mounted() {
    // 如果有业务明细表单，判断表单权限
    if (this.formItemMap.get('fieldlistM')) {
      this.perm = this.formItemMap.get('fieldlistM').perm
      this.$emit('data-ready', this.perm)
    }
  },
  created() {
    this.formItemMap.forEach((value, key) => {
      this.setArray(value)
      if (value.name == 'SpanLayout') {
        value.props.items.forEach(item => {
              this.setArray(item)
        })
      }
      if ((value.name == 'SelectInput' && value.props.opts.length != 1) || value.name == 'PayInput') {
        this.handleChange(value.props.opts, key)
      }
    })
  },
  methods: {
    setArray(item) {
      if (!this.$route.name == 'design' && !this.$route.name == 'forms') {
        if (item.name == 'DateTimeRange' || item.name == 'DeptPicker' || item.name == 'EditorInput'
            || item.name == 'FileUpload' || item.name == 'ImageUpload' || item.name == 'MultipleSelect'
            || item.name == 'OrgPicker' || item.name == 'ProcessIndex' || item.name == 'SelectPlus'
            || item.name == 'TableList' || item.name == 'TimeRangePicker') {
          this._value[item.id] = []
        }
    }
    },
    handleChange(e, key) {
      this.formItemMap.get(key).props.options = []
      this.formItemMap.get(key).props.opts = e
      if (this.formItemMap.get(key).name == 'PayInput' || e[e.length - 1] == '4') {
        getPayWay().then(res => {
          this.formItemMap.get(key).props.options = res.data;
        });
      } else if (e[e.length - 1] == '5') {
        getPart().then(res => {
          this.formItemMap.get(key).props.options = res.data.map(item => item.partName);
          this.formItemMap.get(key).props.list = res.data
        });
      } else if (e[e.length - 1] == '6') {
        getArea().then(res => {
          this.formItemMap.get(key).props.options = res.data.map(item => item.areaName);
          this.formItemMap.get(key).props.list = res.data
        });
      } else if (e[e.length - 1] == '7') {
        getFault().then(res => {
          this.formItemMap.get(key).props.options = res.data.map(item => item.faultName);
          this.formItemMap.get(key).props.list = res.data
        });
      }
    },
    showItem(item) {
      if (item.perm == 'E') return true
      else if (this.$route.name =='design') {
        return ((!this.isReadonly(item))
            || item.name === 'SpanLayout') &&
          item.perm !== 'H'
      } else {
        return (this.isReadonly(item) || item.name === 'SpanLayout') &&
          item.perm !== 'H'
      }
    },
    isBlank(val) {
      return (
        !this.$isNotEmpty(val) ||
        (val instanceof String && val.trim() === "") ||
        (Array.isArray(val) && val.length === 0)
      );
    },
    isReadonly1(item, val) {
      // console.log(item,val) val.length>0 看看默认
      if (val && val.length > 0 && item.perm == "R") {
        val.forEach((e, i) => {
          if (JSON.stringify(e) == '{}') {
            val.splice(i, 1)
          }
        })
      }
      return item.perm === 'R'
    },
    isReadonly(item) {
      if (item.perm === 'E') {
        if (this.$route.path == '/workspace/finished' || this.$route.path == '/workspace/cross/finished') {
          return true
        } else {
          return false
        }
      }
      return item.perm === 'R'
    },
    validate(call) {
      let validateResult = []
      if (this.mode === 'PC') {
        this.$refs.form.validate(valid => {
          if (valid) {
            //校验成功再校验内部
            for (let i = 0; i < this.forms.length; i++) {
              validateResult.push(new Promise((resolve) => {
                if (this.forms[i].name === 'OuterForm'
                  && this.forms[i].props.required && this.forms[i].perm !== 'H') {
                  let formRef = this.$refs[this.forms[i].id]
                  if (formRef && Array.isArray(formRef) && formRef.length > 0) {
                    formRef[0].validate(resolve)
                  } else {
                    resolve(true)
                  }
                } else if (this.forms[i].name === 'TableList') {
                  let itemResult = []
                  this.forms[i].props.columns.forEach(item => {
                    if (item.props.required&& item.perm !== 'H') {
                      let formRef = this.rules[item.id]
                      if (formRef && Array.isArray(formRef) && formRef.length > 0) {
                        if (this._value[this.forms[i].id][0] == undefined || this._value[this.forms[i].id][0][item.id] == undefined || this._value[this.forms[i].id][0][item.id] == null || this._value[this.forms[i].id][0][item.id] == '') {
                          itemResult.push(formRef[0].message)
                        }
                      } else {
                        resolve(true)
                      }
                    }
                  })
                  if (itemResult.length > 0) {
                    this.$message.warning(itemResult[0])
                  } else {
                    resolve(true)
                  }
                } else {
                  resolve(true)
                }
              }))
            }
          } else {
            call(valid)
          }
        });
      } else {
        this.forms.forEach(form => {
          validateResult.push(new Promise((resolve) => {
            let formRef = this.$refs[form.id]
            if (form.perm !== 'H' && (formRef && Array.isArray(formRef) && formRef.length > 0)) {
              if (form.props.required && form.name === 'OuterForm') {
                new Promise((res) => {
                    formRef[0].validate(res)
                  }).then(rs => {
                    if (rs) {
                      this.$refs[form.id + '_item'][0].validate(resolve)
                    }
                  })
                // if ((form.name === 'TableList' || form.name === 'OuterForm')) {
                //   //表格等组件先校验再校验内部列
                //   new Promise((res) => {
                //     formRef[0].validate(res)
                //   }).then(rs => {
                //     if (rs) {
                //       this.$refs[form.id + '_item'][0].validate(resolve)
                //     }
                //   })
                // } else {
                //   formRef[0].validate(resolve)
                // }
              } else if (form.name === 'TableList') {
                let itemResult = []
                  form.props.columns.forEach(item => {
                    if (item.props.required && item.perm !== 'H') {
                      let formRef = this.rules[item.id]
                      if (formRef && Array.isArray(formRef) && formRef.length > 0) {
                        if (this._value[form.id] == undefined || this._value[form.id][0][item.id] == undefined || this._value[form.id][0][item.id] == null || this._value[form.id][0][item.id] == '') {
                          itemResult.push(formRef[0].message)
                        }
                      } else {
                        resolve(true)
                      }
                    }
                  })
                  if (itemResult.length > 0) {
                    this.$message.warning(itemResult[0])
                  } else {
                    resolve(true)
                  }
              } else {
                resolve(true)
              }
            } else {
              resolve(true)
            }
          }))
        })
      }
      if (validateResult.length > 0) {
        Promise.all(validateResult).then((results) => {
          // 检查结果数组中所有校验都成功为true
          const isSuccess = results.every((valid) => valid === true);
          if (isSuccess) {
            this.formRuleValid(call)
          }else {
            call(false)
          }
        })
      }
    },
    formRuleValid(call) {
      if (this.config.validConfig && this.$isNotEmpty(this.config.validConfig.js)) {
        console.log('前置规则校验')
        //如果有设置校验，就执行
        if (!this.validFunc) {
          this.validFunc = new Function('ctx', 'resolve', 'reject', this.config.validConfig.js)
        }
        //TODO 表单校验上下文变量，这里可以自己注入需要的字段，默认只有表单数据
        const ctx = { ...this._value }
        new Promise((resolve, reject) => {
          this.validFunc(ctx, resolve, reject)
        }).then(() => call(true)).catch(err => {
          call(false)
          this.$message.warning(err || '表单规则校验失败，请检查参数')
        })
      } else {
        call(true)
      }
    },
    loadFormItemMap(forms, map) {
      forms.forEach((item) => {
        if (item.name === "TableList") {
          map.set(item.id, item);
          this.loadFormItemMap(item.props.columns, map);
        } else if (item.name === "SpanLayout") {
          this.loadFormItemMap(item.props.items, map);
        } else {
          map.set(item.id, item);
        }
      });
    },
    loadFormConfig(forms, rules) {
      setTimeout(() => {
        if (rules.field5017665847262) {
          this.$set(rules, 'field5017665847262', [
            { required: true, message: '请评分', trigger: 'change' },
            { type: 'number', min: 0.5, max: 5, message: '评分必须在半星到5星之间', trigger: 'change' }
          ])
        }
      }, 1000)
      forms.forEach((item) => {
        if (item.name === 'SpanLayout') {
          this.loadFormConfig(item.props.items, rules)
        } else if (item.name === 'TableList') {
          this.loadFormConfig(item.props.columns, rules)
        } else {
          this.$set(this._value, item.id, this.value[item.id])
          if (item.props.required && this.showItem(item) && item.name !== 'OuterForm' && !this.isReadonly(item)) {
            this.$set(rules, item.id, [{
              type: item.valueType === 'Array' ? 'array' : undefined,
              required: true,
              message: `请完成${item.title}`, trigger: 'blur'
            }])
          }
        }
      })
    },
    //解析表单联动规则
    parserRule(cdRule) {
      const condition = cdRule.condition;
      //在这里可以实现一个算法，按条件层级去解析，不需要解析所有条件，先不实现
      if (cdRule.children.length > 0) {
        for (let i = 0; i < cdRule.children.length; i++) {
          const result = this.parserRule(cdRule.children[i]);
          if (cdRule.logic) {
            //如果是且关系，有一个为假就是假
            if (!result) {
              return false;
            }
          } else {
            //如果是或关系，有一个为真就是真
            if (result) {
              return true;
            }
          }
        }
        //遍历完了返回最终结果
        return cdRule.logic;
      } else {
        //解析条件
        try {
          return this.compare(condition);
        } catch (e) {
          return false;
        }
      }
    },
    async doActions(actions) {
      (actions || []).forEach((action) => {
        //执行预设的动作
        switch (action.type) {
          case "SHOW":
            action.targets.forEach((tg) => this.showField(tg));
            break;
          case "HIDE":
            action.targets.forEach((tg) => this.hideField(tg));
            break;
          case "DISABLE":
            action.targets.forEach((tg) => this.disableField(tg));
            break;
          case "UPDATE":
            action.targets.forEach((tg) => this.updateField(tg, action.value));
            break;
          case "ENABLE":
            action.targets.forEach((tg) => this.enableField(tg, action.value));
            break;
        }
      });
    },
    analyseFormRule() {
      if (this.config.ruleType === "SIMPLE") {
        this.analyseRules();
      } else {
        this.analyseJsRules();
      }
    },
    async analyseJsRules() {
      if (!(this.execute instanceof Function)) {
        this.execute = new Function(
          "formData",
          "formMap",
          `${this.config.ruleJs || "function doChange(){}"
          }\r\n doChange(formData, formMap);`
        );
      }
      this.execute(this.value, this.formItemMap);
    },
    async analyseRules() {
      (this.config.rules || []).forEach((rule, i) => {
        //解析表单联动条件
        const result = this.parserRule(rule.condition);
        console.log(`解析规则 ${i + 1}: ${result}`);
        this.doActions(result ? rule.action.with : rule.action.other);
      });
    },
    compare(condition) {
      //要判断组件类型，再取其值
      const source = this._value[condition.field];
      //动态调用函数
      let compareType = null;
      switch (condition.fieldType) {
        case "AmountInput":
        case "NumberInput":
        case "Score":
        case "CalcFormula":
          compareType = "numCompare";
          break;
        case "TextInput":
        case "TextareaInput":
        case "SelectInput":
        case "Location":
        case "Provinces":
          compareType = "strCompare";
          break;
        case "MultipleSelect":
          compareType = "strArrCompare";
          break;
        case "DateTime":
          compareType = "timeCompare";
          break;
        case "DateTimeRange":
          compareType = "timeArrCompare";
          break;
        case "DeptPicker":
        case "UserPicker":
          compareType = "orgCompare";
          break;
      }
      return CompareFuncs[compareType][condition.compare](
        source,
        condition.fixed
          ? condition.compareVal
          : this._value[condition.compareVal[0]]
      );
    },
    isRequired(item) {
      return this.rules[item.id] !== undefined && !this.isReadonly(item)
    },
    hideField(id) {
      const field = this.formItemMap.get(id);
      if (field) {
        this.$set(field, "perm", "H");
      }
    },
    showField(id) {
      const field = this.formItemMap.get(id);
      if (field) {
        this.$set(field, "perm", this.formPermHis[id] || "E");
      }
    },
    disableField(id) {
      const field = this.formItemMap.get(id);
      if (field) {
        this.$set(field, "perm", "R");
      }
    },
    enableField(id) {
      const field = this.formItemMap.get(id);
      if (field) {
        this.$set(field, "perm", "E");
      }
    },
    updateField(id, val) {
      const field = this.formItemMap.get(id);
      if (field) {
        this._value[id] = val;
      }
    },
  },
  watch: {
    value: {
      deep: true,
      handler() {
        if (this.config) {
          if (Object.keys(this.formPermHis).length === 0) {
            this.formItemMap.forEach((item) => {
              this.formPermHis[item.id] = item.perm;
            });
          }
          this.analyseFormRule();
        }
      },
    },
  },
};
</script>

<style lang="less" scoped>
.process-form {
  /deep/ .el-form-item__label {
    height: 30px;
    line-height: 30px;
    padding: 0 0;
  }

  /deep/ .van-radio__icon--checked .van-icon {
    background-color: #006c50;
    border-color: #006c50;
  }

  /deep/ .van-cell {
    padding: 10px 0;
    font-size: 1.2rem;
  }
}

/deep/ .readonly {
  font-size: 16px;

  .el-form-item {
    margin-bottom: 10px;

    .el-form-item__label {
      font-weight: bold; /* 设置字体加粗 */
      font-size: 16px;
      color: black;
    }
    .el-form-item__label::before {
      display: none;
    }

    .el-form-item__content {
      padding-left: 5px;
      line-height: 30px;
    }
  }

  .m-form-item {
    padding: 10px 5px;
    margin-bottom: 0;

    .m-form-item_title {
      font-size: 1.3rem;

      .title-required {
        display: none;
      }
    }

    .m-form-item_content {
      padding-left: 4px;
      font-size: 1.4rem;
    }
  }
}
</style>
